import { defaultValue } from "../../../../../tools/utility"
import Immerable from "../../../../GeneralImmer"

export type BrandInstalledFontApiData = {
  asset_hash: string
  created_at: number
  family_name: string
  filename: string
  full_name: string
  is_system_font: boolean
  post_script_name: string
  resource: string
  sub_family_name: string
  uuid: string
}

export type BrandTextSubStyleApiData = {
  color?: string
  font_size_percentage?: number
  full_name?: string
  horizontal_scaling?: number
  is_system_font?: boolean
  letter_spacing?: number | string
  post_script_name?: string
  skew_degree?: number
  sub_category: string
  previewText?: string
}

type BrandTextSubStyleIntersect = Partial<
  Omit<BrandTextSubStyleApiData, "sub_category">
> &
  Partial<BrandTextSubStyle>

export enum SubStyles {
  Other = "other",
  Number = "number",
  Character = "character",
  English = "english",
}

export enum PreviewText {
  Other = "中",
  Number = "0",
  Character = "$",
  English = "a",
}

export const isMinus = (value: string | number | undefined) => value === "-"

class BrandTextSubStyle extends Immerable {
  color = "#000000"

  fontSizePercentage = 100

  fullName: string | null = ""

  horizontalScaling = 0

  isSystemFont = false

  letterSpacing: number | string = 0

  postScriptName: string | null = ""

  skewDegree = 0

  previewText: string = ""

  constructor(
    data: BrandTextSubStyleIntersect = {},
    previewText?: (typeof PreviewText)[keyof typeof PreviewText],
  ) {
    super()
    this.initialize(data, previewText)
  }

  initialize(
    data: BrandTextSubStyleIntersect = {},
    previewText?: (typeof PreviewText)[keyof typeof PreviewText],
  ) {
    data = data || {}
    this.color = data.color || this.color
    this.fontSizePercentage = defaultValue(
      data.font_size_percentage || data.fontSizePercentage,
      100,
    ) as number
    this.fullName = defaultValue(data.full_name || data.fullName, "")
    this.horizontalScaling = defaultValue(
      data.horizontal_scaling || data.horizontalScaling,
      0,
    ) as number
    this.isSystemFont = defaultValue(
      data.is_system_font || data.isSystemFont,
      false,
    ) as boolean
    // Letter Spacing is int in BrandSetting,
    // and for calculation in FE and campaign data is float point number.
    // Do transformation here to avoid do calculation everywhere.
    this.letterSpacing = isMinus(data.letter_spacing || data.letterSpacing)
      ? ((data.letter_spacing || data.letterSpacing) as string)
      : defaultValue(data.letter_spacing || data.letterSpacing, 0)
        ? (defaultValue(
            data.letter_spacing || data.letterSpacing,
            0,
          ) as number) / 1000
        : (defaultValue(data.letter_spacing || data.letterSpacing, 0) as number)
    this.postScriptName = defaultValue(
      data.post_script_name || data.postScriptName,
      "",
    )
    this.skewDegree = defaultValue(
      data.skew_degree || data.skewDegree,
      0,
    ) as number
    this.previewText = defaultValue(
      data.previewText,
      previewText || "",
    ) as string
  }

  clone() {
    const subStyle = new BrandTextSubStyle()
    subStyle.color = this.color
    subStyle.fontSizePercentage = this.fontSizePercentage
    subStyle.fullName = this.fullName
    subStyle.horizontalScaling = this.horizontalScaling
    subStyle.letterSpacing = this.letterSpacing
    subStyle.postScriptName = this.postScriptName
    subStyle.skewDegree = this.skewDegree
    subStyle.isSystemFont = this.isSystemFont
    subStyle.previewText = this.previewText
    return subStyle
  }

  /**
   *
   * @todo Figure out if `resource` should be part of this method (look at BE schema for postBrandWhatever endpoints)
   */
  toCompleteJson(subCategory: string): BrandTextSubStyleApiData {
    return {
      color: this.color,
      font_size_percentage: this.fontSizePercentage,
      full_name: this.fullName || "",
      horizontal_scaling: this.horizontalScaling,
      letter_spacing: this.letterSpacing
        ? Number(this.letterSpacing) * 1000
        : this.letterSpacing,
      post_script_name: this.postScriptName || "",
      skew_degree: this.skewDegree,
      sub_category: subCategory,
      is_system_font: this.isSystemFont,
      previewText: this.previewText,
    }
  }
}

export default BrandTextSubStyle
