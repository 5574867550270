import type { Canvas } from "@napi-rs/canvas"
import Papa from "papaparse"
import { SubStyles } from "../../models/Common/BrandTextStyles/BrandTextStyle/BrandTextSubStyle"
import type Ad from "../../models/Common/Campaign/Ad"
import type AssetImage from "../../models/Common/Campaign/Ad/AssetImage/index.js"

interface TrimImageReturnProps {
  canvas: HTMLCanvasElement
  filename: string
}
interface BlobWithNameProps {
  blob: Blob
  name: string
}

const URL_PATTERN = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i",
) // fragment locator

const IRI_PATTERN =
  /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
const IRI_PATTERN_GROUP =
  /((?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?)/i

export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms + 30)
  })

export const download = (url: Blob | MediaSource, downloadName = "Ads") => {
  const eleLink = document.createElement("a")
  eleLink.style.display = "none"
  eleLink.href = URL.createObjectURL(url)
  eleLink.setAttribute("download", downloadName)
  document.body.appendChild(eleLink)
  eleLink.click()
  document.body.removeChild(eleLink)
}

export const downloadByPureUrl = (url: string, downloadName = "Ads") => {
  const eleLink = document.createElement("a")
  eleLink.style.display = "none"
  eleLink.href = url
  eleLink.setAttribute("download", downloadName)
  document.body.appendChild(eleLink)
  eleLink.click()
  document.body.removeChild(eleLink)
}

export const downloadAssetImage = async (assetImage: AssetImage) => {
  const image = await fetch(assetImage.resource)
  const blob = await image.blob()
  const dataURL = URL.createObjectURL(blob)

  const link = document.createElement("a")
  link.href = dataURL
  link.download = assetImage.filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadFile = (blob: Blob, filename: string) => {
  downloadByPureUrl(URL.createObjectURL(blob), filename)
}

export const getIsEmail = (email: string) => {
  let isEmail = false
  // eslint-disable-next-line no-useless-escape
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (regexEmail.test(email)) {
    isEmail = true
  }
  return isEmail
}

export const transformAdToBase64 = async (
  ad: Ad,
  imageSmoothingQuality: ImageSmoothingQuality = "low",
  renderBox = false,
  renderFilters = true,
  size = 150,
  file = false,
) => {
  if (ad.size.width < size || ad.size.height < size)
    size = ad.size.width > ad.size.height ? ad.size.height : ad.size.width
  const canvas = document.createElement("canvas")

  // get the ratio
  const widthRatio = size / ad.size.width
  const heightRatio = size / ad.size.height
  const ratio = widthRatio > heightRatio ? widthRatio : heightRatio

  canvas.width = ad.size.width * ratio
  canvas.height = ad.size.height * ratio

  const ctx = canvas.getContext("2d")
  if (!ctx) return ""

  ctx.imageSmoothingQuality = imageSmoothingQuality
  ctx.scale(ratio, ratio)

  await ad.render(ctx, renderBox, renderFilters)

  if (file) {
    // To Blob
    const blob = await new Promise<Blob | null>((resolve) => {
      canvas.toBlob(resolve, "image/webp", 0.7)
    })

    const file = new File([blob as BlobPart], "layout-thumbnail.webp", {
      type: "image/webp",
    })
    return file
  }

  return canvas.toDataURL("image/png", 0.7)
}

export const getBase64ImageUrl = (
  img: CanvasImageSource,
  positionX,
  positionY,
  canvasWidth: number,
  canvasHeight: number,
) => {
  const canvas = document.createElement("canvas")
  canvas.width = !canvasWidth ? (img.width as number) : canvasWidth
  canvas.height = !canvasHeight ? (img.height as number) : canvasHeight
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D
  ctx.drawImage(
    img,
    0,
    0,
    img.width as number,
    img.height as number,
    positionX,
    positionY,
    img.width as number,
    img.height as number,
  )
  const dataURL = canvas.toDataURL()
  return dataURL
}

export const getBase64Image = (
  imageUrl: string,
  positionX,
  positionY,
  canvasWidth: number,
  canvasHeight: number,
) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.crossOrigin = "Anonymous"
    image.src = imageUrl
    image.onload = () => {
      resolve(
        getBase64ImageUrl(
          image,
          positionX,
          positionY,
          canvasWidth,
          canvasHeight,
        ),
      )
    }
    image.onerror = (err) => {
      reject(err)
    }
  })

export const getTrimmedFiles = async (files: FileList): Promise<FileList> => {
  const isPng = (f: File) => f?.type === "image/png" || f?.name?.endsWith("png")
  if (![...files].some(isPng)) {
    return files
  }

  const croppedFile: Record<string, null | File> = {}
  const croppedPromiseArray: Promise<TrimImageReturnProps>[] = []
  const fileList = new DataTransfer()

  Object.keys(files).forEach((key: string) => {
    if (files[Number(key)].type === "image/png") {
      croppedFile[key] = null
      croppedPromiseArray.push(getTrimmedCanvasFromPngFile(files[Number(key)]))
    } else {
      croppedFile[key] = files[Number(key)]
    }
  })

  const croppedCanvases = await Promise.all(croppedPromiseArray)

  const croppedBlobs = await Promise.all(
    croppedCanvases.map(
      (item) =>
        new Promise((resolve, reject) => {
          item.canvas.toBlob((blob) =>
            blob ? resolve({ blob, name: item.filename }) : reject(),
          )
        }),
    ),
  )

  Object.keys(croppedFile).forEach((key) => {
    if (!croppedFile[key]) {
      const data = croppedBlobs.shift() as BlobWithNameProps
      fileList.items.add(new File([data.blob], data.name))
    } else {
      fileList.items.add(croppedFile[key] as File)
    }
  })

  return fileList.files
}

/**
 * To trim transparent part from PNG
 * @param file - the file to be trimmed
 * @returns {Promise} trimmed images with filename
 * reference: https://gist.github.com/remy/784508/218a4a3c80bf24ad74dd2217fd688d83012a5d22
 */
export const getTrimmedCanvasFromPngFile = (
  file: File,
): Promise<TrimImageReturnProps> => {
  const url = URL.createObjectURL(file)
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D

  const rowBlank = (imageData: ImageData, width: number, y: number) => {
    for (let x = 0; x < width; ++x) {
      if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false
    }
    return true
  }

  const columnBlank = (
    imageData: ImageData,
    width: number,
    x: number,
    top: number,
    bottom: number,
  ) => {
    for (let y = top; y < bottom; ++y) {
      if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false
    }
    return true
  }

  const calculateTrimmedPngRange = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D
    const width = canvas.width
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    let top = 0
    let bottom = imageData.height
    let left = 0
    let right = imageData.width

    while (top < bottom && rowBlank(imageData, width, top)) top += 1
    while (bottom - 1 > top && rowBlank(imageData, width, bottom - 1))
      bottom -= 1
    while (left < right && columnBlank(imageData, width, left, top, bottom))
      left += 1
    while (
      right - 1 > left &&
      columnBlank(imageData, width, right - 1, top, bottom)
    )
      right -= 1

    if (
      top === 0 &&
      bottom === imageData.height &&
      left === 0 &&
      right === imageData.width
    )
      return canvas
    // If image don't have any transparency, return image without trimming
    if (top === bottom && right === left) return canvas

    const trimmed = ctx.getImageData(left, top, right - left, bottom - top)
    const copy = canvas.ownerDocument.createElement("canvas")
    const copyCtx = copy.getContext("2d") as CanvasRenderingContext2D
    copy.width = trimmed.width
    copy.height = trimmed.height
    copyCtx.putImageData(trimmed, 0, 0)

    return copy
  }

  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)
      if (file?.type !== "image/png" || !file?.name?.endsWith("png")) {
        return resolve({ canvas, filename: file.name })
      }
      return resolve({
        canvas: calculateTrimmedPngRange(canvas),
        filename: file.name,
      })
    }
    img.src = url
    img.onerror = () => reject()
  })
}

export const getIsValidURL = (string: string) => !!URL_PATTERN.test(string)

export const getIsValidIRI = (string: string) => !!IRI_PATTERN.test(string)

export const findFirstValidIRI = (string = "") => {
  const match = string.match(IRI_PATTERN_GROUP)
  if (match) {
    return match[1]
  }
  return null
}

interface RgbObject {
  r: number
  g: number
  b: number
}

export const hexToRgb = (hex: string): RgbObject | null => {
  const shorthandRegex = /^#?([A-Fa-f\d])([A-Fa-f\d])([A-Fa-f\d])$/i
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  const result =
    /^#?([A-Fa-f\d]{2})([A-Fa-f\d]{2})([A-Fa-f\d]{2})([A-Fa-f\d]{2})?$/i.exec(
      hex,
    )
  return result
    ? {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16),
      }
    : null
}

export const checksRGB = (color: number): number => {
  if (color <= 0.03928) {
    return color / 12.92
  }
  return ((color + 0.055) / 1.055) ** 2.4
}

export const getColorObject = (color: RgbObject): RgbObject => {
  const colorObj = {
    r: checksRGB(color.r / 255),
    g: checksRGB(color.g / 255),
    b: checksRGB(color.b / 255),
  }
  return colorObj
}

export const calculateContrastRatio = (color1: number, color2: number) => {
  const colorOneObject = getColorObject(
    hexToRgb(color1.toString()) as RgbObject,
  )
  const colorTwoObject = getColorObject(
    hexToRgb(color2.toString()) as RgbObject,
  )
  const colorOneL =
    0.2126 * colorOneObject.r +
    0.7152 * colorOneObject.g +
    0.0722 * colorOneObject.b
  const colorTwoL =
    0.2126 * colorTwoObject.r +
    0.7152 * colorTwoObject.g +
    0.0722 * colorTwoObject.b
  if (colorOneL > colorTwoL) {
    return (colorOneL + 0.05) / (colorTwoL + 0.05)
  }
  return (colorTwoL + 0.05) / (colorOneL + 0.05)
}

export const detectBrowser = () => {
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    return {
      type: "ios",
      isMobile: true,
    }
  }
  if (/Android|webOS/i.test(navigator.userAgent)) {
    return {
      type: "android",
      isMobile: true,
    }
  }
  return {
    type: "desktop",
    isMobile: false,
  }
}

export const basicCJKAndSymbolsRegex =
  /[\u4E00-\u9FFF\u3400-\u4DBF\u3000-\u303F\uFF01-\uFFEF]|[\u2190-\u21FF]/

export const hasCJKAndSymbols = (s = "", extended = false) => {
  const extendedRegex =
    /[\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]|[\u2190-\u21FF]/
  return s.search(extended ? extendedRegex : basicCJKAndSymbolsRegex) > -1
}

export const hasLatin = (string: string) => string.search(/[A-Za-z]/) > -1

export const splitCJK = (string = "") => {
  const strings: string[] = []
  ;[...string].forEach((char) => {
    if (!strings.length) {
      strings.push(char)
      return
    }

    const currentIsCJK = char.search(basicCJKAndSymbolsRegex) > -1
    const previousIsCJK =
      strings[strings.length - 1][0].search(basicCJKAndSymbolsRegex) > -1
    if (currentIsCJK === previousIsCJK) {
      strings[strings.length - 1] += char
    } else {
      strings.push(char)
    }
  })
  if (!strings.length) strings.push("")
  return strings
}

const englishList = /[a-zA-Z]/
/**
 * It's the unicodes of the following characters(including fullwidth)
 * ¥ $ ₩ ₫ ฿ ₱ £ € ~ @ # % & * + - / =
 */
export const characterList =
  /[\u00A5\u0024\u20A9\u20AB\u0E3F\u20B1\u00A3\u20AC\u007E\u0040\u0023\u0025\u0026\u002A\u002B\u002D\u002F\u003D\uFFE5\uFF04\uFFE6\uFFE1\uFF5E\uFF20\uFF03\uFF05\uFF06\uFF0B\uFF0D\uFF0F\uFF1D]/
const numberLst = /[0-9]/

export const getCategoryByContent = (string = "") => {
  if (string.search(characterList) > -1) return SubStyles.Character
  if (string.search(numberLst) > -1) return SubStyles.Number
  if (string.search(englishList) > -1) return SubStyles.English
  return SubStyles.Other
}

export const splitContent = (string = "") => {
  const strings: string[] = []
  ;[...string].forEach((char) => {
    if (!strings.length) {
      strings.push(char)
      return
    }

    const currentCategory = getCategoryByContent(char)
    const previousCategory = getCategoryByContent(
      strings[strings.length - 1][0],
    )
    if (currentCategory === previousCategory) {
      strings[strings.length - 1] += char
    } else {
      strings.push(char)
    }
  })
  if (!strings.length) strings.push("")
  return strings
}

interface Point {
  x: number
  y: number
}

const dotProduct = (u: Point, v: Point): number => u.x * v.x + u.y * v.y

export const vector = (a: Point, b: Point): Point => ({
  x: b.x - a.x,
  y: b.y - a.y,
})

export const pointInRectangle = (
  point: Point,
  a: Point,
  b: Point,
  c: Point,
) => {
  // a: bottom left, b: top left, c: top right
  const dotABAP = dotProduct(vector(a, b), vector(a, point))
  const dotABAB = dotProduct(vector(a, b), vector(a, b))
  const dotBCBP = dotProduct(vector(b, c), vector(b, point))
  const dotBCBC = dotProduct(vector(b, c), vector(b, c))
  // eslint-disable-next-line yoda
  return (
    0 <= dotABAP && dotABAP <= dotABAB && 0 <= dotBCBP && dotBCBP <= dotBCBC
  )
}

export const rotatePoint = (center: Point, point: Point, degrees: number) => {
  const rad = degrees * (Math.PI / 180)
  const x =
    Math.cos(rad) * (point.x - center.x) -
    Math.sin(rad) * (point.y - center.y) +
    center.x
  const y =
    Math.sin(rad) * (point.x - center.x) +
    Math.cos(rad) * (point.y - center.y) +
    center.y
  return { x, y }
}

export const rotateRectangle = (
  x: number,
  y: number,
  width: number,
  height: number,
  degrees: number,
) => {
  const center = { x: x + width / 2, y: y + height / 2 }
  return {
    a: rotatePoint(center, { x, y: y + height }, degrees),
    b: rotatePoint(center, { x, y }, degrees),
    c: rotatePoint(center, { x: x + width, y }, degrees),
    d: rotatePoint(center, { x: x + width, y: y + height }, degrees),
  }
}

export const hasPSDHeader = async (file: Blob) => {
  const headerSlice = file.slice(0, 4)
  const headerBuffer = await headerSlice.arrayBuffer()
  const headerArray = new Int8Array(headerBuffer.slice(0, 4))
  const psdHeader = [56, 66, 80, 83]
  for (let i = 0; i < psdHeader.length; i++) {
    if (psdHeader[i] !== headerArray[i]) {
      return false
    }
  }
  return true
}

const googleDriveRegex =
  /^(https?:\/\/)?drive.google.com\/file\/d\/([0-9A-z-]+)/

export const normalizeGoogleDrivePreviewURL = (url = "") => {
  const base = "https://drive.google.com/uc?export=view&id="
  const match = url.match(googleDriveRegex)
  if (!match) {
    return url
  }

  return `${base}${match[2]}`
}

export const defaultValue = <T>(
  value: T | null | undefined,
  defaultValue: T | null,
): T | null =>
  value === undefined || value === null || Number.isNaN(value)
    ? defaultValue
    : value

/** TODO(david): refactor this so TypeScript won't yell anymore.  */
export const catchFragmentRemoval = () => {
  if (typeof Node === "function" && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild
    // eslint-disable-next-line func-names
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error(
            "Cannot remove a child from a different parent",
            child,
            this,
          )
        }
        return child
      }
      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments)
    }

    const originalInsertBefore = Node.prototype.insertBefore
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            "Cannot insert before a reference node from a different parent",
            referenceNode,
            this,
          )
        }
        return newNode
      }
      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments)
    }
  }
}

export const findFields = (
  obj: Record<string, any>,
  targetField: string,
  results: Record<string, any> = [],
) => {
  // TODO(david): refactor this or turn off the eslint rule.
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const field in obj) {
    if (field === targetField) {
      results.push(obj[field])
    }
    if (typeof obj[field] === "object" && obj[field]) {
      findFields(obj[field], targetField, results)
    }
  }
  return results
}

/** Error message of the CSV upload feature */
interface ErrorMessage {
  row: number
  column: string
}

/** Error of the papaparse library */
interface PapaparseErrorType {
  code: string
  message: string
  row: number
  type: string
}

/** The papaparse library is written in pure JavaScript ¯\_(ツ)_/¯ */
interface PapaparseType {
  data: Record<string, string>[]
  errors: PapaparseErrorType[]
}

/** Validate image urls in the CSV file */
export async function validateCsv(csv: Blob): Promise<ErrorMessage[]> {
  // Use FileReader API to get the content
  const readerPromise: Promise<FileReader["result"]> = new Promise(
    (resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.readAsText(csv)
    },
  )

  return readerPromise.then((readerResult) => {
    const errorMessages: ErrorMessage[] = []

    // Parse the content using papaparse library
    const { data } = Papa.parse(readerResult, {
      header: true,
      transformHeader: (header: string) => header.replace(/[\s\u3000]/g, ""),
      transform: (value: string) => value.trim(),
    }) as PapaparseType

    // Scan through all image urls
    data.forEach((row, index) => {
      Object.keys(row).every((column) => {
        if (
          column.includes("img_url") ||
          column.includes("image_url") ||
          column.includes("圖")
        ) {
          const imageUrl = row[column]
          // Bypass empty image url
          if (imageUrl === "") return true

          // Cannot apply URL_PATTERN here because it's too restrictive.
          // Regex reference: https://stackoverflow.com/a/3809435
          const urlRegex =
            /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
          // Example: img://c3d47e4d1b6242b2894f3309a80e0c09/b1efb7f4ff9a98cb
          // Or img://b2335332-d28f-449b-8d00-80a550b43046/b1efb7f4ff9a98cb
          // Also supports URLs without the final part, like: img://568b34ff-1bbe-4c01-9ff5-3253f659f5dc
          const customUrlRegex =
            /^img:\/\/([0-9a-f]{32}|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:\/[0-9a-f]{16})?$/
          // Supports filenames with characters from different languages.
          // Unicode character classes are not directly supported in JavaScript in regular expressions, use Unicode ranges instead.
          // Modified to make the extension truly optional and allow whitespace in filenames
          const imageFilenameRegex =
            /^[a-zA-Z0-9_\s\u00C0-\u017F\u0400-\u04FF\u0500-\u052F\u1E00-\u1EFF\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uAC00-\uD7AF]+(?:\.(jpe?g|png))?$/

          if (
            !urlRegex.test(imageUrl) &&
            !customUrlRegex.test(imageUrl) &&
            !imageFilenameRegex.test(imageUrl)
          ) {
            errorMessages.push({ row: index + 2, column })
            // Doesn't return false here because we want to collect all invalid urls.
          }
        }
        return true
      })
    })
    return errorMessages
  })
}

/**
 * Utility function to generate custom url that users can use in the CSV.
 * This is used when user clicks copy link icon when hovering the image grid under brand settings page
 */
export const copyLinkToCsv = (assetHash: string) => `img://${assetHash}`

/** Replace prod to staging in the image/font link if the environment is staging/preview  */
export function replaceAssetLinkInStagingEnv(link: string): string {
  if (!link) return ""

  // Check if the env is browser.
  if (typeof window === "undefined") {
    return link
  }

  const { VITE_API_URL } = import.meta.env
  const isStaging = VITE_API_URL?.includes("staging")

  return isStaging ? link.replace("prod", "staging") : link
}

export const checkFontLoadable = (fontFile: File) => {
  const readerPromise: Promise<{
    arrayBuffer: FileReader["result"]
    fontFile: File
  }> = new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = (e) => {
      if (e.target?.result) resolve({ arrayBuffer: e.target.result, fontFile })
    }
    reader.readAsArrayBuffer(fontFile)
  })

  let fontFace: FontFace
  return readerPromise.then(({ arrayBuffer, fontFile }) => {
    fontFace = new FontFace("checkingUploading", arrayBuffer as BinaryData)
    return new Promise((res, rej) => {
      fontFace
        .load()
        .then(() => {
          document.fonts.add(fontFace)
          res(fontFile)
        })
        .catch(() => {
          rej(new Error(fontFile.name))
        })
    })
  })
}

export function createNapiCanvas(
  width: number,
  height: number,
): Canvas | HTMLCanvasElement {
  if (typeof window === "undefined") {
    // Node.js
    const { createCanvas } = require("@napi-rs/canvas")
    const canvas = createCanvas(width, height)
    canvas.width = width
    canvas.height = height
    return canvas
  }
  // Browser
  const canvas = document.createElement("canvas")
  canvas.width = width
  canvas.height = height
  return canvas
}
